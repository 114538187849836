import React from "react";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import styled from "@emotion/styled";
import { GreenInfoText, WhiteLinkText, TitleText } from "./styled-components";

const TopAbsWrapper = styled("div")`
  position: absolute;
  z-index: 40;
  top: 0;
  left: 15%;
  width: 70%;
  height: 85%;
  @media (max-width: 950px) {
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TopImageDiv = styled("div")`
  position: absolute;
  z-index: 30;
  top: 0;
  left: 5%;
  height: 90%;
  width: 90%;
  overflow: hidden;
  @media (max-width: 950px) {
    left: 0;
    width: 100%;
  }
`;
const TopInfoDiv = styled("div")`
  position: absolute;
  z-index: 40;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  @media (max-width: 950px) {
    left: 0;
    width: 100%;
  }
`;

const LeftColorBlock = styled("div")`
  position: absolute;
  z-index: 20;
  bottom: 5%;
  left: 5%;
  width: 60%;
  height: 70%;
  background-color: #82d5d7;
  overflow: hidden;
  @media (max-width: 950px) {
    left: 0;
    width: 100%;
  }
`;

const RightColorBlock = styled("div")`
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 5%;
  background-color: #b0bcbc;
  width: 40%;
  height: 60%;
  @media (max-width: 950px) {
    left: 0;
    width: 100%;
  }
`;

const LandingTop = ({ image }) => {
  const patternImgs = useStaticQuery(graphql`
    query {
      icons: allFile(filter: { relativeDirectory: { eq: "patterns" } }) {
        nodes {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
          id
        }
      }
    }
  `);
  const patterns = {};
  patternImgs.icons.nodes.forEach((img) => {
    patterns[img.sharp.fluid.originalName.split(".")[0]] = img;
  });

  console.log(patterns);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 30vh;
        min-height: 800px;
        @media (max-width: 950px) {
          min-height: 500px;
        }
      `}
    >
      <TopAbsWrapper>
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
          `}
        >
          <TopImageDiv>
            <Image
              fadeIn={false}
              loading="eager"
              objectFit="cover"
              fluid={image.image.fluid}
              alt={image.alt}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
              `}
            />
            {/* <ImageWrapper fluid={image.image.fluid} alt={image.alt} /> */}
          </TopImageDiv>
          <TopInfoDiv>
            <div
              css={css`
                display: flex;
                height: 100%;
                width: 100%;
                @media (max-width: 950px) {
                  flex-direction: column;
                  justify-content: flex-end;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 100%;
                  width: 50%;
                  box-sizing: border-box;
                  padding: 20px;
                  background-color: white;
                  @media (max-width: 950px) {
                    height: 100%;
                    width: 100%;
                  }
                `}
              >
                <GreenInfoText>FORMATION ENERGY</GreenInfoText>
                <TitleText>Solar development for communities</TitleText>
              </div>
              <div
                css={css`
                  display: flex;
                  width: 50%;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: flex-start;
                  min-width: 200px;
                  @media (max-width: 950px) {
                    height: 100%;
                    width: 100%;
                  }
                `}
              >
                <div
                  css={css`
                    width: 50%;
                    height: 33.5%;
                    background-color: #46c39f;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 950px) {
                      width: 100%;
                      height: 100%;
                      padding: 10px 0;
                    }
                  `}
                >
                  <WhiteLinkText to="#contact">Contact Us</WhiteLinkText>
                </div>
              </div>
            </div>
          </TopInfoDiv>
        </div>
      </TopAbsWrapper>
      <LeftColorBlock>
        <Image
          fadeIn={false}
          loading="eager"
          objectFit="cover"
          fluid={patterns.whiteleaf.sharp.fluid}
          alt={image.alt}
          css={css`
            width: 101.5%;
            height: 101.5%;
            margin-left: -5px;
            object-fit: cover;
            opacity: .3;
          `}
        />
      </LeftColorBlock>
      <RightColorBlock>
        <Image
          fadeIn={false}
          loading="eager"
          objectFit="cover"
          fluid={patterns.whitelines.sharp.fluid}
          alt={image.alt}
          css={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .3;
          `}
        />
      </RightColorBlock>
    </div>
  );
};

export default React.memo(LandingTop);
