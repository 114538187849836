import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import {
  BlurbText,
  TextImageWrapper,
  GreenLinkText,
} from "../components/styled-components";

import MainLayout from "../components/main-layout";
import LandingTop from "../components/landing-top";
import ImageWrapper from "../components/image-wrapper";
import Contact from "../components/contact";

const RootIndex = ({ data }) => {
  const imgTop = data.allContentfulFormationImage.nodes.find(
    (image) => image.slug === "top"
  );
  const imgMid = data.allContentfulFormationImage.nodes.find(
    (image) => image.slug === "mid"
  );
  const imgBottom = data.allContentfulFormationImage.nodes.find(
    (image) => image.slug === "bottom"
  );
  return (
    <>
      <MainLayout>
        <br />
        <LandingTop image={imgTop} />
        <br />
        <br />
        <br />
        <TextImageWrapper reverse={true}>
          <div
            data-sal="slide-right"
            css={css`
              width: 40%;
              display: flex;
              flex-direction: column;
              @media (max-width: 950px) {
                text-align: center;
                width: 90vw;
              }
            `}
          >
            <BlurbText size={"1.5rem"} margin={true}>
              Formation Energy
            </BlurbText>
            <BlurbText size={".85rem"} margin={true}>
              is a Woman Owned business that develops distributed and
              utility-scale solar projects in the PJM region.
            </BlurbText>
            <GreenLinkText to={"#contact"}>
              Email us for more information
            </GreenLinkText>
          </div>
          <div
            data-sal="slide-left"
            css={css`
              width: 50%;
              display: flex;
              justify-content: center;
              @media (max-width: 950px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={imgMid.image.fluid} alt={imgMid.alt} />
          </div>
        </TextImageWrapper>
        <br />
        <br />
        <br />
        <TextImageWrapper>
          <div
            data-sal="slide-right"
            css={css`
              width: 50%;
              display: flex;
              justify-content: center;
              @media (max-width: 950px) {
                margin-bottom: 20px;
                width: 90vw;
              }
            `}
          >
            <ImageWrapper fluid={imgBottom.image.fluid} alt={imgBottom.alt} />
          </div>
          <div
            data-sal="slide-left"
            css={css`
              width: 40%;
              display: flex;
              flex-direction: column;
              @media (max-width: 950px) {
                text-align: center;
                width: 90vw;
              }
            `}
          >
            <BlurbText size={"1.5rem"} margin={true}>
              We have decades of
            </BlurbText>
            <BlurbText size={".85rem"} margin={true}>
              experience with specific expertise in Greenfield and Brownfield
              project development. We are driven by a passionate commitment to
              benefit local communities and the planet.
            </BlurbText>
            <GreenLinkText to={"#contact"}>
              Email us for more information
            </GreenLinkText>
          </div>
        </TextImageWrapper>
        <br />
        <br />
        <br />
        <Contact />
      </MainLayout>
    </>
  );
};

export default RootIndex;

export const query = graphql`
  query LandingQuery {
    allContentfulFormationImage(filter: { tags: { in: "landing" } }) {
      nodes {
        image {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        alt
        slug
      }
    }
  }
`;
