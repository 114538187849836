import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { BlurbText, WhiteLinkText } from "./styled-components";

const ModalDisplay = styled("div")`
  width: 90vw;
  max-width: 500px;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  position: fixed;
  border: 5px solid #215732;
  border-radius: 5px;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 500px) {
    height: Calc(90vh -60px);
    margin-top: Calc(-45vh + 60px);
    margin-left: -45vw;
  }
`;

const BackDrop = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #48c2c5;
  opacity: 0.5;
  z-index: 75;
`;

const ThankYouModal = ({ handleClose }) => {
  return (
    <>
      <BackDrop />
      <ModalDisplay>
        <BlurbText size="1.5rem">
          Thank you for contacting Foundation Energy!!
        </BlurbText>
        <br />
        <BlurbText size="1rem">We'll be in touch shortly.</BlurbText>
        <br />
        <br />
        <div
          css={css`
            width: 100px;
            height: 50px;
            padding: 10px;
            background-color: #46c39f;
            text-align: center;
            z-index: 150;
          `}
          onClick={handleClose}
          onKeyDown={handleClose}
          aria-hidden="true"
        >
          <WhiteLinkText to="/">HOME</WhiteLinkText>
        </div>
      </ModalDisplay>
    </>
  );
};

export default ThankYouModal;
