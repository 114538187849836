import React, { useRef, useState, useEffect } from "react";
import Image from "gatsby-image";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";
import { useInput } from "../hooks/use-input";
import { BlurbText } from "./styled-components";

import ThankYouModal from "./thank-you-modal";

import "../styles/contact.css";

const ContactIconWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin: 20px 0;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ContactIconInfo = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media (max-width: 950px) {
    text-align: center;
  }
`;

const Contact = () => {
  const contactIcons = useStaticQuery(graphql`
    query {
      icons: allFile(filter: { relativeDirectory: { eq: "contact-icons" } }) {
        nodes {
          sharp: childImageSharp {
            fixed(height: 50, width: 50) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
          id
        }
      }
    }
  `);
  const icons = {};
  contactIcons.icons.nodes.forEach((icon) => {
    icons[icon.sharp.fixed.originalName.split(".")[0]] = icon;
  });

  const { value: name, bind: bindName, reset: resetName } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const {
    value: telephone,
    bind: bindTelephone,
    reset: resetTelephone,
  } = useInput("");
  const { value: subject, bind: bindSubject, reset: resetSubject } = useInput(
    ""
  );
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  );
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [thankYouModal, setThankYouModal] = useState(false);

  const contactForm = useRef(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const form = {
      "form-name": contactForm.current.getAttribute("name"),
      name: name,
      email: email,
      subject: subject,
      telephone: telephone,
      message: message,
    };
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(form).toString(),
    })
      .then((res) => {
        setSent(true);
        resetName();
        resetEmail();
        resetTelephone();
        resetSubject();
        resetMessage();
      })
      .catch((err) => setError(err.toString()));
  };

  const handleClose = () => {
    setThankYouModal(false);
  };

  useEffect(() => {
    if (sent) {
      setThankYouModal(true);
      setSent(false);
    }
  }, [sent]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setError(null);
    }
  }, [error]);

  return (
    <>
      {thankYouModal && <ThankYouModal handleClose={handleClose} />}
      <div
        id="contact"
        css={css`
          width: 100%;
          height: auto;
          padding: 50px 155;
          text-align: center;
          display: flex;
          border-top: 5px solid #607272;
          border-bottom: 5px solid #607272;
          @media (max-width: 950px) {
            padding: 50px 25px;
            flex-direction: column;
          }
        `}
      >
        <section
          id="contact-form"
          css={css`
            width: 65%;
            height: auto;
            display: flex;
            flex-direction: column;
            background-color: #e7ebeb;
            align-items: center;
            @media (max-width: 950px) {
              width: 100%;
            }
          `}
        >
          <br />
          <BlurbText color={"#46c39f"} size={"1.5rem"}>
            Contact
          </BlurbText>
          <br />
          <form
            className="contact-form"
            name="contact"
            method="POST"
            data-netlify="true"
            onSubmit={handleSubmit}
            ref={contactForm}
          >
            <section className="two-wide">
              <input
                placeholder="Name"
                className="form-input"
                id="form-name"
                type="text"
                name="name"
                value={name}
                {...bindName}
              />
              <input
                placeholder="E-Mail"
                className="form-input"
                id="form-email"
                type="email"
                name="email"
                value={email}
                {...bindEmail}
              />
            </section>
            <section className="two-wide">
              <input
                placeholder="Telephone"
                className="form-input"
                id="form-telephone"
                type="text"
                name="telephone"
                value={telephone}
                {...bindTelephone}
              />
              <input
                placeholder="Subject"
                className="form-input"
                id="form-subject"
                type="text"
                name="subject"
                value={subject}
                {...bindSubject}
              />
            </section>
            <textarea
              placeholder="Message"
              className="form-area"
              id="form-message"
              type="text"
              name="message"
              value={message}
              {...bindMessage}
            />
            <button className="submit-button" type="submit">
              Send Message
            </button>
          </form>
        </section>
        <section
          id="contact-info"
          css={css`
            width: 35%;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: space-around;
            box-sizing: border-box;
            padding: 50px 20px;
            @media (max-width: 950px) {
              width: 100%;
              padding: 10px 10px;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
            }
            @media (max-width: 768px) {
              width: 100%;
              padding: 10px 10px;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
            }
          `}
        >
          <ContactIconWrapper>
            <Image
              fadeIn={false}
              loading="eager"
              css={css`
                margin-right: 20px;
                @media (max-width: 950px) {
                  margin-right: 0px;
                }
              `}
              fixed={icons.phoneIcon.sharp.fixed}
              alt={"Call Us"}
            />
            <ContactIconInfo>
              <BlurbText size={"1.5rem"} color={"#46c39f"} margin={true}>
                Call Us
              </BlurbText>
              <BlurbText size={".85rem"}>+ 215 378 4763</BlurbText>
            </ContactIconInfo>
          </ContactIconWrapper>
          <ContactIconWrapper>
            <Image
              fadeIn={false}
              loading="eager"
              css={css`
                margin-right: 20px;
                @media (max-width: 950px) {
                  margin-right: 0px;
                }
              `}
              fixed={icons.locationIcon.sharp.fixed}
              alt={"Locate Us"}
            />
            <ContactIconInfo>
              <BlurbText size={"1.5rem"} color={"#46c39f"} margin={true}>
                Locate Us
              </BlurbText>
              <BlurbText size={".85rem"}>Formation Energy LLC</BlurbText>
              <BlurbText size={".85rem"}>Philadelphia, PA 19147</BlurbText>
            </ContactIconInfo>
          </ContactIconWrapper>
          <ContactIconWrapper>
            <Image
              fadeIn={false}
              loading="eager"
              css={css`
                margin-right: 20px;
                @media (max-width: 950px) {
                  margin-right: 0px;
                }
              `}
              fixed={icons.mailIcon.sharp.fixed}
              alt={"Write Us"}
            />
            <ContactIconInfo>
              <BlurbText size={"1.5rem"} color={"#46c39f"} margin={true}>
                Write Us
              </BlurbText>
              <BlurbText size={".85rem"}>laurie@formationsolar.com</BlurbText>
            </ContactIconInfo>
          </ContactIconWrapper>
        </section>
      </div>
    </>
  );
};

export default Contact;
