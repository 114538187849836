import React from "react";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

const SingleImageWrapper = styled("div")`
  width: 100%;
  position: relative;
  padding-top: 75%;
  overflow: hidden;
`;

const InnerWrapper = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  object-fit: cover;
`;

const ImageWrapper = ({ fluid, alt }) => {
  return (
    <SingleImageWrapper>
      <InnerWrapper>
        <Image
          fadeIn={false}
          loading="eager"
          objectFit="cover"
          fluid={fluid}
          alt={alt}
          css={css`
            width: 100%;
            object-fit: cover;
          `}
        />
      </InnerWrapper>
    </SingleImageWrapper>
  );
};

export default React.memo(ImageWrapper);